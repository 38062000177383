<template>
  <ValidationObserver
    v-if="passes"
    ref="observer">
    <form
      class="patient-personal-info-form"
      @submit.prevent="validateThenContinue"
    >
      <div
        v-for="(appointment, i) in appointmentsPayload"
        :key="i">
        <template v-if="appointmentsPayload.length > 1">
          <p
            class="schedule-title _p0 _m0"
            :style="globalTheme.color">{{appointment.patient.firstName}} {{appointment.patient.lastName}}</p>
          <div
            class="themed-strip _mbxs"
            :style="globalTheme.borderColorPrimary" />
        </template>
        <div grid="row wrap bottom">
          <form-input
            :id="`fname_${i}`"
            :key="`fname_${i}`"
            v-model="appointment.patient.firstName"
            column="xs-12 s-12"
            class="_mbs"
            validations="required"
            :label="labels.firstName"
            type="text"/>
          <form-input
            :id="`lname_${i}`"
            :key="`lname_${i}`"
            v-model="appointment.patient.lastName"
            column="xs-12 s-12"
            class="_mbs"
            validations="required"
            :label="labels.lastName"
            type="text"/>
          <h4
            class="_mt0"
            column="xs-12 s-12"
            :style="globalTheme.colorBlack">{{labels.dateOfBirth}}</h4>
          <form-multi-select
            :id="`month_${i}`"
            v-model="appointment.personalInformation.month"
            class="_mbs"
            column="xs-12 m-4"
            :label="labels.month"
            :validations="(appointment.personalInformation.month==='' || appointment.personalInformation.month===null) ? 'required' : ''"
            :multiple="false"
            placeholder=""
            :allow-empty="false"
            :options="monthOptions"
            :searchable="true"
            :canClear="false"/>
          <select-day
            v-model="appointment.personalInformation.day"
            column="xs-12 m-3"
            :canClear="false"
            :chosen-month="appointment.personalInformation.month"
            :chosen-year="appointment.personalInformation.year"
            :user-index="i"
            :label="labels.day"
          />
          <form-multi-select
            :id="`year_${i}`"
            v-model="appointment.personalInformation.year"
            class="_mbs"
            column="xs-12 m-5"
            :label="labels.year"
            :validations="(appointment.personalInformation.year==='' || appointment.personalInformation.year===null) ? 'required' : ''"
            :multiple="false"
            placeholder=""
            :allow-empty="false"
            :options="yearOptions"
            :searchable="true"
            :canClear="false"
            />
          <div
            v-if="i !== 0"
            column="xs-12 s-12"
            class="_mbs">
            <form-checkbox
              :id="`pairContactInfoToPrimary_${i}`"
              v-model="appointment.personalInformation.pairContactInfoToPrimary"
              :label="labels.usePrimaryAccountAsContact"/>
          </div>
          <form-input
            v-if="appointment.personalInformation.pairContactInfoToPrimary && i !== 0"
            :id="`email_${i}`"
            :key="`email_${i}`"
            v-model="appointmentsPayload[0].personalInformation.email"
            disabled
            column="xs-12 s-12"
            class="_mbs"
            :validations="appointmentsPayload[0].personalInformation.email.length === 0 ? 'required' : '' "
            :label="labels.email"
            type="email"/>
          <form-input
            v-else
            :id="`email_${i}`"
            :key="`email_${i}`"
            v-model="appointment.personalInformation.email"
            column="xs-12 s-12"
            class="_mbs"
            validations="required|email"
            :label="labels.email"
            type="email"/>
          <form-input
            v-if="appointment.personalInformation.pairContactInfoToPrimary && i !== 0"
            :id="`phone_${i}`"
            :key="`phone_${i}`"
            v-model="appointmentsPayload[0].personalInformation.phone"
            disabled
            inputmode="numeric"
            column="xs-12 s-12"
            class="_mbs"
            :validations="appointmentsPayload[0].personalInformation.phone.length === 0 ? 'required' : ''"
            placeholder="(555) 555-5555"          
            :label="labels.phone"/>
          <form-input
            v-else
            :id="`phone_${i}`"
            :key="`phone_${i}`"
            v-model="appointment.personalInformation.phone"
            inputmode="numeric"
            column="xs-12 s-12"
            class="_mbs"
            placeholder="(555) 555-5555"
            validations="required"
            mask="(###) ###-####"
            :label="labels.phone"/>
        </div>
      </div>
      <div grid="row wrap">
        <div
          column="xs-12 s-12"
          class="_mbxs" style="display: flex; align-items: center;">
          <form-checkbox
            id="reminders_signup"
            v-model="appointmentRemindersSignup"
            :label="labels.appointmentRemindersSignup"/>
        </div>
        <div
          column="xs-12 s-12"
          class="_mbxs" style="display: flex; align-items: center;">
          <form-checkbox
            id="accept_terms"
            v-model="acceptTerms"
            required
            :label="labels.acceptTerms"/>
        </div>
        <div
          column="xs-12 s-12"
          class="_mbs" style="display: flex; align-items: center;">
          <form-checkbox
            id="certify_adult"
            v-model="certifyAsAdult"
            required
            :label="labels.certifyAsAdult"/>
        </div>
      </div>
      <div
        v-if="responseMessage"
        column="xs-12 s-12"
        class="_mbs">
        <response-flash-notification :error-obj="responseMessage"/>
      </div>
      <button
        :disabled="submitting"
        :style="globalTheme.button"
        type="submit"
        class="sm">{{labels.confirmAndBook}}
        <font-awesome-icon
          v-if="submitting"
          icon="spinner"
          pulse
          aria-label="Loading..."
          class="_mlxxs"
        />
      </button>
    </form>
  </ValidationObserver>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { UserCreatedAppointment, PersonalInformationLabels, SchedulePageLabels, Option, ConfirmBookingValues, AppointmentPayload, PersonalInformation, AvailableAppointment, ServiceResponseMessage, PersonalInfoPayload, CurrentProvider } from '@/types'
  import { scheduleViewChildRoutes } from '@/router.ts'
  import { dayOptions, yearOptions } from '@/ts/helpers/schedule.helpers.ts'

  import FormInput from '@molecules/Form/FormInput.vue'
  import FormMultiSelect from '@molecules/Form/FormMultiSelect.vue'
  import SelectDay from '@molecules/Schedule/SelectDay.vue'
  import FormCheckbox from '@molecules/Form/FormCheckbox.vue'
  import { confirmAppointment, confirmSbAppointment } from '@/ts/service-helpers/services/confirm-appointments.ts'

  import date  from 'date-and-time'
  import { isMock } from '@/../env.config.ts'
  let createMockedPersonalInformation
  if (isMock) {
    createMockedPersonalInformation = require('@/mock-data/personal-information.ts')
  }
  import ScheduleRouterGuard from '@/mixins/ScheduleRouterGuards.ts'
  import { trackSchedulingStep } from '@/ts/adobe-analytics/index'
  import { isIE } from '@/ts/helpers/IE'
  import 'date-and-time/locale/es';
  
  const confirmBookingValues = {
    appointmentRemindersSignup: true,
    acceptTerms: false,
    certifyAsAdult: false
  } as ConfirmBookingValues

  export default defineComponent({
    name:'personal-information',
    components: {
      FormInput,
      FormMultiSelect,
      SelectDay,
      FormCheckbox
    },
    mixins: [ScheduleRouterGuard],
    props: {
      value: {
        required: true,
        type: Array as () => UserCreatedAppointment[]
      },
      page: {
        required: true,
        type: Object as () => SchedulePageLabels
      },
      localizedRoute: {
        required: true,
        type: String
      },
      provider: {
        required: true,
        type: Object as () => CurrentProvider
      },
    },
    data() {
      return {
        submitting: false,
        responseMessage: null as null|ServiceResponseMessage,
        ...confirmBookingValues,
        passes: false,
        dayOptions: dayOptions as Option[],
        yearOptions: yearOptions as Option[]
      }
    },
    computed: {
      labels(): PersonalInformationLabels {
        return this.page.personalInformation
      },
      // dateTime(): any {
      //   date.locale(this.$route.params.lang)
      //   return date
      // },
      monthOptions(): Option[] {
        const monthOptions = [] as Option[]
        for (let i = 1; i < 13; i++) {
          const date = date.parse(`1-${i}-2000`, 'D-M-YYYY')
          monthOptions.push({ value:date.format(date, 'MM'), label:date.format(date,'MMM') })
        }
        return monthOptions
      },
      appointmentsPayload: {
        get(): UserCreatedAppointment[] {
          return (this as any).value
        },
        set(newVal: UserCreatedAppointment[]) {
          (this as any).$emit('update:value', newVal)
        }
      }
    },
    methods: {
      setDayOptionsByMonthChosen(userIndex: number): Option[] {
        const chosenMonth = this.appointmentsPayload[userIndex].personalInformation!.month as string|Option
        switch ((chosenMonth as Option).value) {
        case '02' :
          return this.dayOptions.slice(0, -2)
        case '04' || '06' || '09' || '11':
          return this.dayOptions.slice(0, -1)
        default:
          return this.dayOptions
        }
      },
      async validateThenContinue(): Promise<void> {
        const isValid = await (this.$refs.observer as any).validate()
        if (isValid.valid) {
          this.sendIt()
        } else {
          const errorEl = this.$el.querySelectorAll('.invalid-anchor')
          errorEl[0].scrollIntoView({ behavior: 'smooth', block: 'start' })
          const firstErrorEl: HTMLInputElement|null = this.$el.querySelector('.error')
          if (firstErrorEl) {
            setTimeout(() => {
              firstErrorEl.focus()
            }, 612)
          }
        }
      },
      sendIt(): void {
        this.submitting = true
        const apptsPayload = this.createAppointmentsPayload() as AppointmentPayload[]
        const query = this.$route.query
        if ( this.appointmentsPayload.length > 1) {
          if (query.isSbAppointment === 'true') {
            confirmSbAppointment(apptsPayload, this.appointmentsPayload)
              .catch((serviceError): void => {
                // eslint-disable-next-line
                console.log(serviceError)
              })
              .finally(() => {
                this.appointmentsPayload
                const nextRoute = scheduleViewChildRoutes[3]
                setTimeout(() => {
                  this.$router.replace({
                    name: nextRoute.name,
                    path: `${this.localizedRoute}/schedule/${nextRoute.path}`,
                    query: query
                  }).then(() => {
                    trackSchedulingStep('event-sb-step-completed', this.provider)
                  })
                  this.submitting = false
                }, isIE() ? 1000 : 0)
              })
          } else  {
            confirmAppointment(apptsPayload, this.appointmentsPayload)
              .catch((serviceError): void => {
                // eslint-disable-next-line
                console.log(serviceError)
              })
              .finally(() => {
                this.appointmentsPayload
                const nextRoute = scheduleViewChildRoutes[3]
                setTimeout(() => {
                  this.$router.replace({
                    name: nextRoute.name,
                    path: `${this.localizedRoute}/schedule/${nextRoute.path}`,
                    query: query
                  }).then(() => {
                    trackSchedulingStep('event-tab-step-completed', this.provider)
                  })
                  this.submitting = false
                }, isIE() ? 1000 : 0)
              })
          }
        } else {
          if (query.isSbAppointment === 'true') {
            confirmSbAppointment(apptsPayload, this.appointmentsPayload)
              .then((res) => {
                const nextRoute = scheduleViewChildRoutes[3]
                this.$router.replace({
                  name: nextRoute.name,
                  path: `${this.localizedRoute}/schedule/${nextRoute.path}`,
                  query: query
                }).then(() => {
                  trackSchedulingStep(
                    'event-sb-step-completed',
                    this.provider
                  )
                })
              })
              .catch((serviceError): void => this.renderServiceError(serviceError))
              .finally(() => {
                this.submitting = false
              })
          } else {
            confirmAppointment(apptsPayload, this.appointmentsPayload)
              .then((res) => {
                const nextRoute = scheduleViewChildRoutes[3]
                this.$router.replace({
                  name: nextRoute.name,
                  path: `${this.localizedRoute}/schedule/${nextRoute.path}`,
                  query: query
                }).then(() => {
                  trackSchedulingStep(
                    'event-tab-step-completed',
                    this.provider
                  )
                })
              })
              .catch((serviceError): void => this.renderServiceError(serviceError))
              .finally(() => {
                this.submitting = false
              })
          }
        }
      },
      renderServiceError(serviceError) {
        this.responseMessage = {
          defaultMessage: serviceError.message || serviceError.defaultMessage,
          messageCode:serviceError.messageCode
        }
      },
      createAppointmentsPayload(): AppointmentPayload[] {
        const primaryInfo = { ...this.appointmentsPayload[0].personalInformation, ...this.appointmentsPayload[0].patient } as PersonalInfoPayload
        return this.appointmentsPayload.map((appt: UserCreatedAppointment): AppointmentPayload => {      
          const personalInfo = { ...appt.personalInformation, ...appt.patient } as PersonalInfoPayload
          const appointment = appt.appointment as AvailableAppointment
          const usePrimaryContact = personalInfo.pairContactInfoToPrimary
          const payload = {
            patient: {
              firstName: personalInfo.firstName,
              lastName: personalInfo.lastName,
              dateOfBirth: `${(personalInfo.month.value as Option)}/${(personalInfo.day.value as Option)}/${(personalInfo.year.value as Option)}`,
              emailAddress: usePrimaryContact ? primaryInfo!.email : personalInfo.email,
              phoneNumber: usePrimaryContact ? primaryInfo!.phone.replace(/\D/g,'') : personalInfo.phone.replace(/\D/g,''),
            },
            scheduleId: appointment.scheduleId,
            lockId: appt.lockId as string,
            optInSms: this.appointmentRemindersSignup ? 'true' : 'false',
          }         
          return payload
        })
      },
      setPersonalInformationState(): void{
        this.appointmentsPayload?.map(appt => {
          if (appt.personalInformation) return
          appt.personalInformation = {
            month: '',
            day: '',
            year: '',
            email: '',
            phone: '',
            pairContactInfoToPrimary: true
          }
        })
        if (isMock) {
          this.appointmentsPayload.map((appt) => {
            appt.personalInformation = createMockedPersonalInformation()
          })
        }
      }
    },
    async created() {
      const insufficientAppointmentData = this.value?.find(user => {
        return !user.patient.firstName || !user.patient.firstName.length ||!user.patient.lastName || !user.patient.lastName.length
      })
      //@ts-ignore
      if (insufficientAppointmentData) {
        //@ts-ignore
        this.returnToFirstRoute()
      } else {
        this.setPersonalInformationState()
        this.passes= true
      }
    }
  })
</script>
